.station-overview{
    /* margin-top: 1rem; */
    padding: 16px 32px;
}
.station-overview h3{
    /* font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #161616;  */



    font-family: "Roboto";
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
}
.haeding-style-charger-info{
    font-family: "Roboto";
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
}
.station-overview p{
    /* font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6F6F6F; */

    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070
}
.station-overview h5{
    /* font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #262626; */

    font-family: "Roboto";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    text-align: left;
    color: #707070;

}
.station-overview input{
    background: #FAFAFA;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.station-overview select{
    background: #FAFAFA;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}

.overview-section-heading{
    border-bottom: 2px solid #E4E7EC;
    margin-bottom: 2rem;
}
.overview-section .station-field{
    display: flex;
    margin-bottom: 1rem;
}
.overview-section .station-field .station-field-div1{
    margin-right: 1rem; 
     width: 30%;
}
.station-field-location{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
 .station-field-location div{
    margin-right: 1rem;
}
.station-field-email{
    position: relative;
}
.station-field-email svg{
    position: absolute;
    margin: 12px 0 0 10px;
}
.station-field-email input{
    padding-left: 40px!important;
}
.img-preview div{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0.5rem 0rem;
}
.img-preview div div{
    margin: 0.5rem;
}
.img-preview span{
    font-weight: bold;
    font-size: larger;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    line-height: 0.5;
    padding: 2px 2px 6px 2px;
}
.img-preview img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
}
.station-connector{
    /* background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px; */
    /* padding: 24px; */
    width: 40%;
}

.station-connector p{
    width: 150px;
}
.station-connector-section{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 105px!important;
}
.station-connector-details{
    display: flex;
    padding: 5px 16px;
    justify-content: space-between;
}
.station-connector div:first-child{
    margin-bottom: 1rem;
} 
.station-connector-section .status{
    text-align: center;
    background: #12B76A;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    width: 66px;
    height: 22px;
    padding-top: 1px;
    color: #ECFDF3;
}
.station-connector-section .status2{
    text-align: center;
    background: #814097;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    width: 66px;
    height: 22px;
    padding-top: 1px;
    color: #ECFDF3;
    border: none;
    margin-left: 3px;
    position: relative;
    top: -2.5px;
}

.station-connector-section-buttons{
    padding: 10px;
    float: right;
}
.station-connector-section-buttons button{
    width: 131px;
    height: 40px;
    background: #D8AE4E;
    border: 1px solid #D8AE4E;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: white;
}
.station-connector-section-buttons button:nth-child(2){
    background: #F04438;
    margin-left: 10px;
}
.station-connector-section-buttons button svg{
    margin-right: 5px;
}
.station-setting-checkboxes{
    width: 40%;
}
.station-setting-checkboxes div{
    display: flex;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 1rem;
    transition: 0.3s;
}
.station-setting-checkboxes div input{
    background: none;
    border:none;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    align-self: flex-start;
    accent-color: #814097;
    cursor: pointer;
}

.station-setting-checkboxes div p span{
    cursor: pointer;
    color: #814097;
    font-weight: bold;
}
input[type="checkbox"]:checked ~ .station-setting-checkboxes div{
    border: 1px solid #D383EE!important;
    background: #FCF3FF!important;
}
.station-setting-btns{
    width: 80%;
    text-align: end;
    margin: 0 2rem 2rem 0;
}
 .cancel-btn, .save-btn{
    padding: 0px 18px;
    width: 160px;
    height: 44px;
    background: #FFFFFF;
    background: #6c757d;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.save-btn{
    background: #814097;
    color: white;
    margin-left: 1rem;
    padding-bottom: 1px;
}
.station-map{
    height: 320px;
}
.station-map  div:first-child{
    width: 500px!important;
    /* height: 40%!important; */
    height: 370px!important;
    z-index: 0;
}
.modal-container{
    position: fixed;
    width: 100%!important;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background-color: #16161669;
}
.modal-container p{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #6F6F6F;
}
.modal-wrapper{
    background-color: white;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    border-radius: 12px;
    padding: 24px;
}
.modal-wrapper-header h4{
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
}

.modal-wrapper-section-header{
    display: flex;
}
.modal-wrapper-section-header svg{
    margin-left: 5px;
    margin-top: 5px;
    cursor: pointer;
}
.model-btn{
    padding: 0px 15px;
    width: 140px;
    height: 34px;
}
.modal-wrapper .station-setting-btns{
    text-align: center;
    width: 100%;
}
.modal-wrapper-section-note{
    width: 300px;
}
.modal-wrapper-section{
    position: relative;
}
.modal-wrapper-section-note{
    position: absolute;
    display: none;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 8px;
    width: 205px;
    padding: 12px;
    top: 0;
    left: 30%; 
}
.modal-wrapper-section-note-header p{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #393939;
}
.modal-wrapper-section-note-pointer{
    position: absolute;
    top: 0;
    left: -10px;
    z-index: 10;
}
.modal-wrapper-section-input{
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #161616;
    padding: 0.5rem;
}
.modal-wrapper-section-input input, .modal-wrapper-section-input select{
    border: none;
    border-right: 1px solid #D0D5DD;
    margin-right: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #161616;
}
.modal-wrapper-section-input input:focus, .modal-wrapper-section-input select:focus{
    outline: none;
}
.modal-wrapper-section-input select{
    border: none;
    width: 100%;
    font-weight: 400;
}





.generate-password-btn{
    border-color: #814097;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background: #fcf3ff;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #814097;
    padding: 8px 22px;
    margin-bottom: 10px;
}

.hide-btn{
    border-color: #814097;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background: #fcf3ff;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #814097;
    padding: 6px 12px;
    margin-left: 12px;
}
.hide-btn svg{
    margin-right: 8px;
    margin-top: -2px;
}
.w-888{
    width: calc(100% - 107px);
}

.notification-check:checked{
    border-color: #b2b2b2!important;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;


    /* background: #F5F0F7!important; */
    background-color: #8E54A2!important;
    /* border-color: #0d6efd; */
}
.connector-type-select-div{
    width: calc(100% - 170px);
}
.connector-type-icon{
    position: relative;
    right: -190px;
}
.add-connector-btn{
    width: 160px;
    height: 36px;
    border-radius: 5px;
    background: #00a651;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    outline: none;
    border: none;
    margin-left: 10px;
}
.add-connector-btn svg{
    margin: 0 8px;
}
.connector-display-div{
    border-color: #e3e3e3;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background: #ffffff;


}

.connector-display-div .div1{
    padding: 49px 32px;
}

.connector-name{
    font-family: "Roboto";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
    margin-top: 17px;
}
.connector-type{
    font-family: "Roboto";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
    margin-top: 6px;
}
.cross-connector-icon{
    margin-top: 50px;
    margin-right: 15px;
}

.add-home-cancel-submitsection{
    border-top: 2px solid #E4E7EC;
    margin-top: 15px;
    padding-top: 32px;
    padding-bottom: 32px;
}

.add-station-submit-btn{
    /* width: 185px; */
    height: 44px;
    border-radius: 5px;
    background: #814097;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    border: none;
    padding: 0px 18px;
    padding-left: 0px;
}
.add-station-submit-btn svg{
    margin: 0px 15px;
}

.add-station-reset-btn{
    width: 185px;
    height: 44px;
    border-radius: 5px;
    background: #b2b2b2;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    border: none;
    margin-left: 20px;
}

.add-station-reset-btn svg{
    margin: 0px 15px;
}


.width-35-percent{
    width: 35%;
}

.station-map-mob{
    display: none;
}

.station-field-location div h6{
    color: #707070;
}

.connector-type-divv{
    width: 40%;
}


@media screen and (max-width: 480px) {
    .station-overview h3 {
        font-size: 16px;
        color: #707070;
        margin-bottom: 4px!important;
    }
    .station-overview p{
        font-size: 9px;
        margin-bottom: 9px!important;
    }
    .overview-section-heading{
        margin-bottom: 9px;
    }
    .station-overview h5{
        font-size: 11px;
    }
    .overview-section .station-field .station-field-div1{
        margin-right: 0px;
        padding-right: 10px;
        width: 40%;
    }
    .width-35-percent{
        width: auto;
    }
    .station-map-desk{
        display: none;
    }
    .station-overview{
        padding: 16px 16px;
    }
    .station-field-location{
        display: block;
    }
    .station-map div div {
        width: calc(100% - 16px)!important;
    }
    .station-map-mob{
        display: block;
    }   
    .station-field-location div h6{
        color: #707070;
        font-size: 11px;
    }
    .haeding-style-charger-info{
        font-size: 16px;
    }
    .overview-section .station-field{
        display: block;
    }
    .connector-type-divv{
        width: auto;
    }
}
