.station-header h3{
    font-family: "Roboto";
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
}
.station-header p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #525252;
}
.add-new-home{
    border-radius: 5px; 
    background: #814097;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    /* text-align: left; */
    color: #ffffff;
    padding: 8px 18px;
    border: none;
    /* float: right; */
}
.add-new-home svg{
    margin-right: 13px;
}
.filter-section{
    z-index: 10;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
}
.search-field{
    position: relative;
    width: 50%;
    cursor: pointer;
}
.search-field input{
    color: #525252;
    padding: 0.4rem 2.3rem 0.4rem 2.5rem;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    width: 100%;
}
.search-field svg{
    position: absolute;
    top:12px;
    left:10px
}
.clr-814097{
    color: #814097;
}
.sort-select{
    border-color: #e3e3e3;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    color: #707070;
}

.filter-data{
    cursor: pointer;
    color: #525252;
    padding: 0.4rem 2.3rem;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin: 0 0.5rem;
    transition: 0.3s;
}
.filter-data svg{
    margin-top: -4px;
    margin-right: 0.5rem;
}
.filter-data:focus{
    border: 1px solid #E7ACFB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
    border-radius: 8px;
}
.filter-section .search-btn{
    color: white;
    width: 144px;
    background: #814097;
    border: 1px solid #814097;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.stations{
    margin: 2rem 0;
}

.home-status-div{
    border-color: #b2b2b2;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background: #fcf3ff;
    width: 175px;
    height: 40px;
    padding: 4.5px 4px;
}

.status-heading-clr{
    font-family: "Roboto";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070!important;
    margin-left: 6px;
}

.home-fontstyle-1{
    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: right;
    color: #707070;
    margin-top: 15px;
}

.home-status-div-show{
    width: 46px;
    height: 30px;
    border-radius: 5px;
    background: #814097;
    padding: 4px 0px;
    text-align: center;
}
.home-status-div-show span{
    font-family: "Roboto";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    color: #fcf3ff;
}

.stations-sorting{
    display: flex;
    justify-content: space-between;
}
.stations-sorting svg{
    margin-left: 0.5rem;
}
.stations-section{
    margin: 2rem 0;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 1px 3px rgba(173, 170, 174, 0.24), 0px 1px 2px rgba(173, 170, 174, 0.08);
    border-radius: 8px;
}
.stations-number{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.stations-number h6{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #947326;
}
.stations-number div{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #814097;
}
.stations-number svg{
    margin-left: 0.5rem;
}
.stations-section-title{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #814097;
}
.stations-section-address{
    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
}
.stations-timming{
    display: flex;
}
.stations-timming div{
    background: #F6FEF9;
    border: 1px solid #039855;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #12B76A;
    height: 30px;
    padding: 0 5px;
    margin-right: 1rem;
}
.stations-timming p{
    /* font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #393939; */


    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
}
.stations-details{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.stations-location{
    display: flex;
}
.stations-location svg{
    margin-right: 5px;
    margin-top: 5px;
}
.stations-location h6{
    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
}
.stations-location div p{
    /* font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6F6F6F; */


    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #707070;
}
.stations-charger-types{
    display: flex;
    justify-content: start; 
    width: 70%;
    flex-wrap: wrap;
    gap: 32px;
}
.stations-charger-types .stations-charger-type{
    display: flex;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 0.5rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #161616;
    height: 40px;
    width: 30%;
}
.stations-charger-type .station-name{
    width: 100%;

    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    /* text-align: left; */
    color: #707070;
}

.stations-charger-types .stations-charger-type svg{
    margin-right: 5px;
    fill: #00A651!important;
    
}
.stations-charger-types .stations-charger-type .border{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.stations-charger-status{
    border-radius: 4px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding: 0 3px;
    text-align: center;
}
.available{
    background: #12B76A;
    background: #0E8D06;
    background: #00A651;
    
}
.maintenance{
    background: #EE7200;
}
.offline{
    background: #C6C6C6!important;
    color: black!important;
}
.charging{
    /* background: #1570EF!important; */
    background: #0BA5EC!important;
}
.faulted{
    /* background: #1570EF!important; */
    background: #EE7200!important;
}

.filter-details{
    z-index: 10;
    position: absolute;
    top: 35%;
    right: 50px;
    /* width: 30%; */
    width: 430px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    border-radius: 12px;
}
.filter-details h5{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #161616;
}
.filter-details svg{
    margin-right: 10px;
}
.filter-details-header{
    display: flex;
    justify-content: space-between;
}

.filter-details-header div:first-child{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;color: #814097;
}
.filter-details-header div:last-child{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #393939;
}
input[type="checkbox"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }
  input[type="checkbox"]:focus{
  outline: 0;
  }
  .toggle-station{
    height: 20px;
    width: 36px;
    border-radius: 16px;
    display: block;
    position: relative;
    margin: 0;
    background-color: gainsboro;
    transition: all .2s ease;
    margin-right: 8px;
  }
  .toggle-station:after{
      content: '';
      position: absolute;
      top: 1.5px;
      left: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: white;
      box-shadow: 0 1px 2px rgba(44,44,44,.2);
      transition: all .2s cubic-bezier(.5,.1,.75,1.35);
  }
  .toggle-station:checked{
    background: #814097;
  }  
   .toggle-station:checked:after{
      left: -2px;
      transform: translatex(20px)
  }
  .filter-details-section{
      margin-top: 0.5rem;
  }
  .filter-details-section-items{
      display: flex;
      flex-wrap: wrap;
  }
.filter-details-section-items div{
    flex: 48%;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    padding: 7px;
    margin: 4px 0;
    color: #161616;
    transition: 0.3s;
}
.filter-details-section-items .item-foucs{
    background: #FCF3FF;
    border: 1px solid #F3D1FF;
    border-radius: 8px;
}
.filter-details-section-items div:nth-child(odd){
    margin-right: 2px;
}
.filter-details-section-items div:nth-child(even){
    margin-left: 2px;
}
.filter-details-section-items .item{
    position: relative;
}
.filter-details-section-items .item svg:last-child{
    position: absolute;
    left: 12px;
    top:13px;
}
.filter-details-section-items .item-1 svg:last-child{
    left: 11px;
    top:11px;
}
.filter-details-section-items .item-2 svg:last-child{
    left: 11px;
    top:15px;
}
.model-station-list:hover{
    background-color: #f4d2ff;
}
.model-station-list-active{
    background-color: #f4d2ff;
}



/* cfghdh */
