.div-flex-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin: 0;
    background-color: #f0f0f0;
  }
  
  .progress-container {
    position: relative;
    width: 120px;
    height: 120px;
    text-align: center;
  }
  
  .progress-ring {
    width: 100%;
    height: 100%;
    border: 10px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    clip: rect(0, 90px, 120px, 0);
  }
  
  .progress-bar {
    width: 100%;
    height: 100%;
    background-color: #3498db;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    clip: rect(0, 60px, 120px, 0);
    transition: transform 0.2s ease-in-out;
  }
  
  .percentage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  




  /* ... Existing CSS ... */

.progress-bar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.2s ease-in-out;
  }
  
  .border {
    border: 10px solid rgba(0, 0, 0, 0.2);
    clip: rect(0px, 60px, 120px, 0px);
  }
  
  .fill {
    background-color: #3498db;
    clip: rect(0px, 120px, 120px, 60px);
  }
  








  .circle-wrap {
    margin: 5px auto;
    width: 150px;
    height: 150px;
    /* background: #fefcff; */
    background: #FCF3FF;
    border-radius: 50%;
    border: 1px solid #cdcbd0;
    border: 1px solid #FCF3FF;
  }
  
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 150px;
   
   
   
    height: 150px;
    position: absolute;
    border-radius: 50%;
  }
  
  .circle-wrap .circle .mask {
    clip: rect(0px, 150px, 150px, 75px);
  }
  
  .circle-wrap .inside-circle {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    background: #fff;
    line-height: 120px;
    text-align: center;
    margin-top: 14px;
    margin-left: 14px;
    color: #1e51dc;
    color: #814097;
    position: absolute;
    z-index: 100;
    font-weight: 700;
    font-size: 2em;
  }
  
  /* color animation */
  
  /* 3rd progress bar */
  /* .mask  */
  .fill {
    clip: rect(0px, 75px, 150px, 0px);
    background-color: #814097;
  }
  
  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 4s;
    transform: rotate(135deg);
  }
  
  @keyframes fill{
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }












  @keyframes growProgressBar {
    0%, 33% { --pgPercentage: 0; }
    100% { --pgPercentage: var(--value); }
  }
  
  @property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
  }
  
  .ddiv[role="progressbar"] {
    --size: 12rem;
    --fg: #369;
    --fg: #814097;
    --bg: #def;
    --bg: #FCF3FF;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: var(--size);
    height: var(--size);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: 
      radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
      ;
    font-family: Helvetica, Arial, sans-serif;
    font-size: calc(var(--size) / 5);
    color: var(--fg);
  }
  
  .ddiv[role="progressbar"]::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
  }