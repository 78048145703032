*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box; 
  font-family: 'Roboto', sans-serif;
}


/* sidebar css started  */


.sidebar{
  width: 285px;
  height: 100vh;  
  background: #FFFFFF;
  border-right: 1px solid #e2dfdf;
  position: fixed;
}
.sidebar .logo-div{
  padding-top: 22px;
}
.hdr-txt{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
      line-height: 18px;
  letter-spacing: 0.02em;  
  color: #707070;
  /* background: #814097; */
}
.pt-12{
  padding-top: 12px;
}
.pt-10{
  padding-top: 8px;
}
.pl-24{
  padding-left: 24px;
}
.pr-24{
  padding-right: 24px;
}
.pl-8{
  padding-left: 8px;
}
.sidebar-items{
  padding-top: 31px;
}
.sidbar-ul{
  padding-left: 0px;
  list-style: none;
  position: relative;
}

.sidbar-ul li{
  cursor: pointer;
  padding: 15;
  align-items: center;
  padding: 8px 0;
  padding-left: 5px; 
  width: calc(100% - 32px);;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  margin-left: 16px;
  margin-bottom: 12px;
}
.sidbar-ul img{
  height: 24px;
  width: 24px;
  padding: 3.5px;
}
.sidbar-ul li svg{
  fill: #707070;
  /* fill="#707070" */
  /* margin-top: 9px; */
  /* padding-top: 8px; */
}
.sidbar-ul li span{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 400;
  color: #393939;
  margin-left: 12px;
  padding-right: 85px;
}
.sidbar-ul li p{
  width: 30px;
  text-align: center;
}
.sidbar-ul .active{
  background: #FCF3FF;
}
.sidbar-ul .active svg{
  fill: #814097
}
.sidbar-ul .active span{
  color: #814097;
  font-weight: 600;
  
}
.sidbar-ul li:hover{
  /* padding-left: 25px; */
}
.sidbar-ul li:hover{
  background: #FCF3FF;
  font-weight: 600;
}
.sidbar-ul li span:hover{
  font-weight: 600;
}
.sidbar-bottom{
  position: absolute;
  bottom: 0;
  border-top: 2px solid #E0E0E0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 2rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #393939;
}
.sidbar-bottom div{
  cursor: pointer;
}
.sidbar-bottom div svg{
  margin-right: 5px;
  margin-bottom: 5px;
}
.sidbar-bottom div:nth-child(1){
  margin-bottom: 1rem;

}
.content-div{
  /* width: calc(100% - 280px); */
  width: calc(100% - 285px);
  min-height: 100vh;
  /* margin-left: 280px; */
  margin-left: 285px;
}







/* navbar css started */



.navbarr{
  position: relative;
  padding: 8px 0px;
  gap: 4px;
  width: 100%;
  height: 10vh;
  background: #FFFFFF;
  /* border-bottom: 1px solid #F4F4F4; */
  border-bottom: 1px solid #e2dfdf;
}
.nav-div1{
  padding-left: 32px;
  padding-top: 17px;
  padding-bottom: 17px;
}
.navbarr .nav-title{
  font-family: 'IBM Plex Sans Thai';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #707070;
}
.nav-div2{
  width: auto;
  /* background-color: #F4F4F4; */
  padding-right: 44px;
}
.navbar-drop-down{
  /* position: absolute;
  background-color: white;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 0px 0px 8px 8px;
  padding: 1rem 1.2rem;
  right: 50px;
  top: 80px;
  z-index: 1; */

  position: absolute;
  background-color: white;
  box-shadow: 0px 20px 24px -4px rgb(16 24 40 / 10%), 0px 8px 8px -4px rgb(16 24 40 / 4%);
  border-radius: 0px 0px 8px 8px;
  padding: 4px 7px;
  right: 50px;
  top: 72px;
  z-index: 1;
  border: 1px solid #F4F4F4;

}
.navbar-drop-down-section{
  border-bottom: 1px solid #E0E0E0;
}
.navbar-drop-down-section:last-child{
  border: none;
}
.navbar-drop-down-section div{
  padding: 8px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #393939;
  transition: 0.3s;
  width: 190px;
}
.navbar-drop-down-section div a{
  color: inherit;
  text-decoration: none;
}
.navbar-drop-down-section div svg{
  margin-right: 10px;
}
.navbar-drop-down-div-active, .navbar-drop-down-section div:hover{
  color: #814097!important;
  background-color: #FCF3FF;
  fill: #814097;
}
.bell-icon{
  margin-top: 24.67px;
}
.cw-div{
  padding-top: 21px;
}
.cw{
  margin-top: -10px;
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #FCF3FF;
  border-radius: 200px;
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #814097;
  margin-left: 32.5px;
  margin-right: 12px;
  padding: 8px 0px;
  /* margin-top: 21px; */
}
.charge-f1{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #707070;
}
.charge-f2{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #b2b2b2;
}
.pt-9{
  padding-top: 9px;
}



/* navbar css ended  */





/* overview page css started  */

.overview-page{
  width: 100%;
  padding: 32px 32px;
}

.map-status-div{
  width: 220px;
}

.ov-heading1{
  font-family: "Roboto";
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  padding-bottom: 4px;
}

.map-statuses-div{
  width: calc(100% - 220px);
  background: #fbfbfb;
}

.charger-status-div{
  border-right: 1px solid #e2dfdf!important;
  padding-bottom: 10px;
  width: 20%;

}

.custom-tooltip {
  background: #fff;
  color: #333;
  padding: 10px;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
  border-radius: 5px;
}

.custom-tooltip .marker {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #B76AD1!important;
  border-radius: 50%;
  margin-right: 5px;
}

.width-18{
  width: 19%;
}

.pl-30{
  padding-left: 22px;
}

.ov-style2{
  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  padding-top: 12px;
}

.border-right-none{
  border-right: none!important;
  border: none!important;
}

.map-statuses .charger-status-div span{
  color: #707070;
}
.clr-707070{
  color: #707070;
}

.map-div{
  padding: 16px 0px;
  height: 532px;
}

.map-div > div > div > div{
  width: calc(100% - 317px)!important;
  height: auto!important;
}

/* map css ended  */

  /* section three css  */
.bg-blue{
  background: rgb(3, 3, 132)!important;
}
.bg-814097{
  background-color: #814097!important;
}

.overview-sec3{
  padding: 16px 0px;
}
.w-32{
  width: 32%;
}
.w-68{
  width: 68%;
}
.w-30{
  width: 30%;
}
.overview-sec3 .search-input{
  background: #fbfbfb;
  border-color: #e3e3e3;
  border-width: 1px;
  border-style: solid;
}
.overview-sec3 .form-check-input{
  width: 25px;
  height: 25px;
}
.view-btn{
  background-color: #814097;
  padding: 5px 10px;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  text-decoration: none;
  display: block;
  width: 46px;
}
.view-btn:hover{
  color: #ffffff;  
}
.week-label-div{
  background-color: #FFFFFF;
  height: 23px;
  width: calc(100% - 43px);
  margin-left: 38px;
  margin-top: -67px;
  position: relative;
  z-index: 0;
}
.week-label-div .dayy{
  width: 14.285%;
}
input[type=checkbox]:checked{
  background-color: #814097!important;
  accent-color: green!important;
  border: none!important;
}
.overview-sec3 label{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #b2b2b2;
  padding-top: 1px;
}
.overview-sec3 .search-btn{
  width: 126px;
  height: 36px;
  border-radius: 5px;
  background: #814097;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border: none;
}



/* .section 4  */

.overview-sec4{
  padding: 16px 0px;
  position: relative;
  z-index: 100;
  /* background-color: #3b0cf9; */
}
.w-20{
  width: 20%;
}
.overview-sec4 h5{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #814097;
}
.overview-sec4 h4{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.overview-sec4 .scrollable-box{
  width: 100%;
  height: 248px;
  /* height: 100%; */
  border-color: #eeeeee;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  overflow-y: auto;
  padding: 11px 10px;
}
.overview-sec4 .scrollable-box h6{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
}

/* .overview-sec4 .scroll-y-auto{
  overflow-y: scroll;
} */

.status-ul{
  list-style: none;
  position: absolute;
  right: 0px;
}
.status-ul li{
  display: inline-block;
  /* width: auto; */
  margin-left: 15px;
}
.status-ul li .div11{
  width: 13px;
  height: 13px;
  background: #814097;
  border-radius: 12px;
  display: inline-block;
  margin-right: 5px;
}
.status-ul li .div12{
  width: 13px;
  height: 13px;
  background: #EE7200;
  display: inline-block;
  border-radius: 12px;
  margin-right: 5px;
}
.status-ul li .div13{
  width: 13px;
  height: 13px;
  background: #FF0000;
  border-radius: 12px;
  display: inline-block;
  margin-right: 5px;
}
.font-style1121{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.clr-ff0000{
  color: #ff0000;
}

/* overview css ended  */

.clr-danger{
  color: #ff0000!important;
}
.clr-success{
  color: #12b76a;
}
.clr-warning{
  color: #ee7200;
}

.rdt_TableHeadRow{
  /* background-color: #EEEEEE!important; */
  background-color: #FBFBFB!important;
}

.generic-section{
  padding: 16px 0px;
  margin-top: 16px;
}
.ov-style3{
  font-family: "Roboto";
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.ov-style4{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}

.filter-list-ul{
  list-style: none;
  
}
.filter-list-ul li{
  display: inline-block;
  padding: 8px 17px;
  border-color: #eeeeee;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin: 0px 5px;
  cursor: pointer;
  padding-top: 5px;
}

.filter-list-ul li span{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  margin-left: 10px;
}
.filter-list-ul .active-op1{
  background: #fcf3ff
}

.filter-list-ul .active-op1 svg{
  fill: #814097!important;
}
.filter-list-ul .active-op1 svg path {
  fill: #814097!important;
}


.filter-list-ul .active-op1 span{
  color: #814097;
}

/* .date-picker-li{
  height: 41px;
  position: relative;
  top: 24px;
} */
.custom-datepicker {
  /* display: inline-block!important; */
  width: 100px;
  border: none;
  background: none;
  /* position: relative;
  margin-left: 20px;
  margin-top: -40px!important;
  float: left; */
  
}

.custom-datepicker:focus{
  border: none;
  outline: none;
}

.custom-datepicker-range{
  border: none;
  background: none;
}
.custom-datepicker-range:focus{
  border: none;
  outline: none;
}
.apexcharts-toolbar{
  display: none!important;
}
.energy-used-graph{
  border: 1px solid #e2dfdf;
  border-radius: 5px;
  padding: 16px 16px;
  margin-top: 16px;
}

.ov-style5{
  font-family: "Roboto";
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.unit-style{
  margin-left: 15px;
  position: relative;
  bottom: -10px;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  /* text-align: right; */
  color: #707070;
}
.time-style{
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #707070;
  /* position: relative; */
  margin-top: -40px;
  margin-left: 15px;
}

.energy-stats-section{
  margin-top: 32px;
  margin-bottom: 12px;
  display: flex;
}

.energy-stat-card{
  padding: 10px 0px;
  padding-left: 20px;
  width: 25%;
  /* border-left: 1px solid #E5E5E5; */
  border-left: 2px solid #e2dfdf;
}

.energy-stat-card h5 {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}

.energy-stat-card h3{
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #707070;
  margin-top: 7px;
}
.energy-stat-card h6{
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.energy-stat-card h6 span{
  padding-right: 4px;
}
.energy-stat-card h6 span svg{
  margin-right: 6px;
}
.energy-stat-card h6 .green{
  color: #12b76a;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
}

.energy-stat-card h6 .red{
  color: #ff0000;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
}


.energy-stat-card h6 .green{
  color: #12b76a;
  font-size: smaller
}
  



/* add new home page  */


.Station-detail{
  width: 100%;
  padding: 32px;
}
.pages-routes1{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.pages-routes2{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #C6C6C6;
  /* border: 1.33333px solid #C6C6C6; */
  margin-left: 18px;
  margin-right: 18px;
}
.pages-routes3{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #814097;
}
.station-detail-title{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #161616;
  margin-top: 20px;
}
.station-detail-card{
  padding: 24px 0px 24px 32px;
  gap: 24px;

  width: 100%;
  /* height: 275px; */

  /* White */

  background: #FFFFFF;
  /* Gray/100

  icon - disabled
  border - form field
  */
  border: 1px solid #F4F4F4;
  /* Shadow/md */

  box-shadow: 0px 4px 8px -2px rgba(173, 170, 174, 0.24), 0px 2px 4px -2px rgba(173, 170, 174, 0.08);
  border-radius: 8px;
}
.staion-detail-cardimg{
  width: 166px;
  height: 166px;
}
.staion-detail-carddetail{
  padding-left: 24px;
  width: calc(100% - 186px);
}
.font-style10{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;

}
.font-style11{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #814097;

  margin-top: 7px;

}
.font-style12{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  margin-top: 7px;
}
.edit-btn-header{
  padding: 7px 23px;
  background: #814097;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

.input-read{
  border: none!important;
  outline: none!important;
  color: #707070!important;
}

.del-icon-hdr{
  margin-left: 12px;
  margin-top: -8px;
}

.font-style131{
  margin-top: 24px;
}
.font-style133{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}

.font-style134{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}


.font-style13{
  padding: 0px 4px;
  gap: 4px;

  width: 38px;
  height: 24px;

  /* Success/25 */

  background: #F6FEF9;
  /* Success/600 */

  border: 1px solid #039855;
  border-radius: 4px;
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */


  /* Success/500 */

  color: #12B76A;
  margin-top: 8px;

}
.font-style14{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */


  /* Gray/700 */

  color: #393939;
  margin-top: 11px;
  margin-left: 8px;
}
.mt-24{
  margin-top: 24px;
}
.font-style15{
  padding: 4px 8px 8px 4px;
  gap: 8px;
  width: 146px;
  height: 32px;
  background: #FCFCFC;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}
.font-style15 img{
  margin-left: 5.33px;
  margin-right: 7.6px;
  margin-bottom: 4.5px;
  margin-top: 0.5px;
}
.font-style16{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #393939;
}
.font-style17{
  padding: 0px 8px;
  gap: 10px;
  width: 24px;
  height: 24px;
  background: #F3D1FF;
  border-radius: 4px;
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  margin-left: 15px;
  margin-bottom: 4px;

  /* Primary/700 */

  color: #672F7A;

}
.font-style18{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #161616;
}
.font-style19{
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #161616;
  margin-left: 8px;
}
.Station-toggle{
  width: calc(100% - 32px);
  margin-top: 24px;
  border-top: 1px solid #E4E7EC;
}
.vertical-line1{
  border-left: 1px solid #E0E0E0;
  margin-left: 10px;
  margin-right: 10px;
}

.home-detail-page-section2{
  padding: 16px 32px;
}

.charge-log{
  padding-top: 16px;
}
.charge-log div h3{
  font-family: "Roboto";
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}
.charge-log div p{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  margin-top: 4px;
}

.arrow-btns{
  outline: none;
  width: 31px;
  height: 30px;
  border-color: #eeeeee;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background: #ffffff
}

.arrow-right-rotate{
  transform: rotate(180deg);
}

.charge-div-border{
  border-bottom: 2px solid #E4E7EC;
}

.del-station-btn{
  background: #ff0000!important;
}





/* area circuit css started  */
.area-circuit{
  padding: 32px 32px;
}

.area-circuit .heading h2{
  font-family: "Roboto";
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}

.area-circuit .heading p {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  margin-top: 4px;
}

.main-area{
  height: 110px;
  border-color: #e3e3e3;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background: #ffffff;
  padding: 19px 18px;
}

.main-area div h3{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #814097;
}

.main-area div p{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  margin-top: 4px;
}

.popup-notification-heading{
  font-family: "Roboto";
  font-size: 45px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  color: #814097;
}

.notification-model{
  padding: 40px 40px;
  width: 500px;
}

.yes-no-btn{
  width: 185px;
  height: 44px;
  border-radius: 5px;
  background: #814097;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  text-align: center;
  border: none;
  margin: 5px 5px;
}


.no-btn-gray{
  background: #b2b2b2!important;
}


.model-desc-style{
  font-family: "Roboto";
  font-size: 33px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #707070;
  margin: 50px auto;
}


/* area circuit css ended  */




/* Admin login page css started  */


.admin-login-page{
  width: 100%;
  height: 100vh;
}

.login-divv{
  width: 460px;
  margin: 0 auto;
  padding-top: 20vh;
}
.login-divv img {
  width: 200px;
  margin-left: 130px;
}

.client-login-divv img {
  width: 300px!important;
  margin-left: 80px;
}

.login-icon-div{
  width: 50px;
}

.login-input-div{
  width: calc(100% - 50px);
}

.admin-login-btn{
  height: 44px;
  border-radius: 5px;
  background: #814097;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #fbfbfb;
  width: 100%;
  border: none;
}


.forget-pass{
  font-family: "Roboto";
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #814097;
  margin-top: 15px;
  margin-bottom: 15px;
}


/* Admin login page css ended  */






.m-navbar{
  display: none;
}

.filter-mobile-icon{
  display: none!important;
}

.m-nav-div{
  display: none;
}
.station-img-mobile-div{
  display: none;
}
/* mobile version css started  */


.font-style-status{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
}

.font-style-status span{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #00a651;
  margin-left: 7px;
}
.btn-view-pur{
  border-radius: 5px;
  background: #814097;
  padding: 5px 23px;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  border: none;
}


.exprt-btn{
  border-radius: 5px;
  background: #814097;
  padding: 6px 17px;
  color: #ffffff;
  border: none;
}
.pointer{
  cursor: pointer;
}








@media screen and (max-width: 480px) {
  .login-divv{
    width: 270px;
    margin: 0 auto;
    padding-top: 20vh;
  }

  .client-login-divv img {
    width: 230px!important;
    margin-left: 9%;
    /* margin: 0 auto; */
  }

  .sidebar{
    display: none;
  }

  .content-div {
    /* width: calc(100% - 280px); */
    width: 100%;
    min-height: 100vh;
    /* margin-left: 280px; */
    margin-left: 0px;
  }
  .navbarr{
    display: none!important;
  }

  /* .filter-list-ul li span {
    display: hidden;
  } */
  .hide-li-item{
    display: none!important;
  }
  .m-navbar{
    display: block;
    width: 100%;
    filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.05 ));
    background: #ffffff;
    text-align: center;
    padding: 13px 0px;
  }
  .overview-page{
    padding: 16px 8px;
  }
  .generic-section{
    margin-top: 0px;
  }
  .ov-style3{
    font-size: 16px;
    margin-bottom: 0px!important;
  }
  .ov-style4{
    font-size: 9px;
    margin-top: 3px;
  }
  .filter-list-ul li{
    padding: 5px 10px;
  }
  .filter-list-ul li span{
    font-size: 11px;
    margin: 0px;
  }
  .energy-used-graph{
    border: none;
    padding: 16px 0px;
    padding-bottom: 0px;
  }
  .ov-style5{
    display: none;
  }
  .unit-style, .time-style{
    font-size: 9px;
  }
  .filter-mobile-icon{
    display: inline-block!important;
  }
  .energy-stats-section{
    display: block;
    margin-bottom: 0px;
  }
  .energy-stat-card{
    width: 46%;
    display: inline-block;
    margin: 8px 2%;
    filter: drop-shadow(0px 2px 3px rgba(0,0,0,0.05 ));
    background: #ffffff;
    border-radius: 5px;
  }
  .energy-stat-card h5, .energy-stat-card h6, .energy-stat-card h6 .red {
    font-size: 9px;
  }
  .energy-stat-card h3{
    font-size: 21px;
  }



  .charge-log div h3{
    font-size: 16px;
  }
  .charge-log div p{
    font-size: 9px;
  }

  .charge-div-border{
    padding-bottom: 5px;
    border: none;
  }
  .m-nav-div{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-bottom: 50px;
    padding-top: 20px;
  }
  .m-nav-div div svg{
    fill: #707070; 
  }
  .m-nav-div div .m-active-link{
    fill: #814097;
     
  }

  .hdr-on-mobile{
    display: none;
  }
  .station-img-mobile-div{
    display: block;
    margin-bottom: 16px;
  }
  
}











/* .fQaieT:not(:last-of-type) { */
/* .fQaieT:not(:first-of-type) { */
/* .fQaieT {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0,.12);

  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: rgba(0,0,0,.12);

  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: rgba(0,0,0,.12);
  
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  
} */